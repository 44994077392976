@import "theme";
@import "~bootstrap/scss/bootstrap";
@import "./bootstrapStyles.scss";
@import "./brandColors.scss";
@import "./nprogress.scss";

html {
    position: relative;
    min-height: 100%;
}

.clickable {
    cursor: pointer;
}

.clickable-underline:hover {
    cursor: pointer;
    text-decoration: underline;
}

.img-cover {
    object-fit: cover;
}

.icon-youtube {
    color: $color-youtube;
}

.icon-facebook {
    color: $color-facebook;
}

.icon-twitter {
    color: $color-twitter;
}

.icon-instagram {
    color: black;
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 1s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.text-multiline {
    white-space: pre-wrap;
    word-break: break-word;
}

.bullets {
    *:not(:last-child):after {
        @extend .bullet;
    }
}

.bullet {
    margin-left: map-get($spacers, 1);
    margin-right: map-get($spacers, 1);
    font-weight: 600;
    content: "·";
}

#user-dropdown {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    color: black;
    margin-left: 0.7rem;
    padding: 0.75rem;
    line-height: 0.625rem;
    height: 2rem;
    width: 2rem;
}

.administration-page {
    .title {
        font-size: map-get($font-sizes, 3);
        margin-top: map-get($spacers, 4);
    }

    > div > div:not(:first-child) {
        margin-top: map-get($spacers, 2);
    }
}

.topic-subtitle {
    color: $secondary;
    font-size: 0.75rem;
}

.hide-scrollbar {
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
}

.card-body-margin-x {
    margin-left: map-get($spacers, 3);
    margin-right: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
        margin-left: map-get($spacers, 4);
        margin-right: map-get($spacers, 4);
    }
}

.card-body-padding-x {
    padding-left: map-get($spacers, 3);
    padding-right: map-get($spacers, 3);

    @include media-breakpoint-up(md) {
        padding-left: map-get($spacers, 4);
        padding-right: map-get($spacers, 4);
    }
}

.cursor-default {
    cursor: default;
}
