// CSS adjustments for Bootstrap that can't be achieved through variables

.badge-light {
    background-color: $gray-200 !important;
}

.jumbotron {
    padding: 2rem;
    background-color: #e9ecef;
    border-radius: 0.3rem;
}
