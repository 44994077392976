@import "../style/bootstrapImports.scss";

#nprogress {
    pointer-events: none;
}

#nprogress .bar {
    background: #29d;

    position: fixed;
    z-index: $zindex-fixed + 1;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1;

    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .bar {
    position: absolute;
}
